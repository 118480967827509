import useTheme from "@/js/composables/useTheme";
import useRegister from "@/js/composables/useRegister";
import { AnalyticsBrowser } from "@segment/analytics-next";

export default () => {
  const { customer, IS_STAGING } = useTheme();
  const register = useRegister();

  const analytics = AnalyticsBrowser.load({
    writeKey: IS_STAGING
      ? "ARIhMqpHNDHiHPIFDuECggr5cXKWgesP"
      : "8ngX7xcZEeSwaqCHiazjnr5VxYHR2tXx",
  });

  const segmentIdentify = () => {
    const traits = {
      ...(customer?.email && {
        email: customer?.email || null,
      }),
      ...((register?.value?.customer_address?.province ||
        customer?.addresses[0]?.province ||
        customer?.addresses[0]?.address1) && {
        default_address: {
          ...((register?.value?.customer_address?.province ||
            customer?.addresses[0]?.province) && {
            state:
              register?.value?.customer_address?.province ||
              customer?.addresses[0]?.province ||
              null,
          }),
          ...(customer?.addresses[0]?.address1 && {
            street: customer?.addresses[0]?.address1 || null,
          }),
        },
      }),
      ...(customer?.phone && {
        phone: customer?.phone || null,
      }),
      ...(register?.value && {
        ...(register?.value?.pet_name && {
          petname: register?.value?.pet_name || null,
        }),
        ...(register?.value?.pet_type && {
          pettype: register?.value?.pet_type || null,
        }),
        ...(register?.value?.customer_address?.province && {
          pethomestate: register?.value?.customer_address?.province || null,
        }),
      }),
    };

    analytics.identify(customer?.id || null, traits, {
      ...(analytics.instance?.user().anonymousId() && {
        anonymousId: analytics.instance?.user().anonymousId(),
      }),
    });
  };

  const segmentTrack = (event: string | string[], properties?: any) => {
    analytics.track(event, properties);
  };

  const segmentPage = (name: string) => {
    analytics.page(name);
  };

  return {
    segmentIdentify,
    segmentTrack,
    segmentPage,
  };
};
